import { Flex, Text } from '@conteg/ui';
import { InfoText } from 'pages/events/info-text';
import { useTranslation } from 'react-i18next';
import {
  CourierAccessCredentialsVerifiedFragment,
  StorageUnitStatusChangedFragment,
} from 'types/generated/graphql';

import { StorageUnitTrackingLockState } from './storage-unit-tracking-lock-state';

export type TableItem =
  | StorageUnitStatusChangedFragment
  | CourierAccessCredentialsVerifiedFragment;

type StorageUnitAdditionalInfoProps = {
  event: TableItem;
};

export const StorageUnitAdditionalInfo = ({
  event,
}: StorageUnitAdditionalInfoProps) => {
  const { t } = useTranslation();

  if (event.__typename === 'StorageUnitStatusChanged') {
    return (
      <Flex gap="3rem">
        <InfoText
          label={t('StorageUnitAdditionalInfo.StorageUnitNumber')}
          value={event.storageUnitName}
        />
        <Flex gap="1rem">
          <Text
            variant="highlight"
            content={t('StorageUnitTracking.LockState')}
          />
          <StorageUnitTrackingLockState lockState={event.lockStatus} />
        </Flex>
      </Flex>
    );
  }

  if (event.__typename === 'CourierAccessCredentialsVerified') {
    return (
      <Flex gap="3rem">
        <InfoText
          label={t('Table.CourierIdentifier')}
          value={
            event.personIdentifier ??
            t('StorageUnitTracking.NoPersonIdentifier')
          }
        />
        <InfoText label={t('Pin')} value={event.pinCode} />
      </Flex>
    );
  }

  return null;
};
