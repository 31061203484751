import {
  Button,
  Flex,
  Grid,
  Input,
  Pagination,
  Select,
  styled,
  Table,
} from '@conteg/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { PageLayout, PageLayoutContent } from 'components/basic-layout';
import { TimeSelect } from 'components/time-select/time-select';
import { DEFAULT_PAGE_SIZE } from 'config';
import {
  FilterTypeSelect,
  FiltrType,
} from 'pages/events/content-tracking/filter-type-select';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  PointTrackingQueryVariables,
  usePointsQuery,
  usePointTrackingQuery,
} from 'types/generated/graphql';
import { formatDateTime, formatDateTimeToIso } from 'utils/format/format';
import { notEmpty } from 'utils/not-empty/not-empty';

import {
  StorageUnitAdditionalInfo,
  TableItem,
} from './storage-unit-additional-info';

const tableColumnHelper = createColumnHelper<TableItem>();

const StyledPaginationWrapper = styled.div`
  margin-top: 8rem;
`;

const EventsTable = styled.div`
  white-space: nowrap;
`;

export const StorageUnitTracking: React.FC = () => {
  const { t } = useTranslation();

  const { data: pointsList } = usePointsQuery();

  const [searchParams, setSearchParams] = useSearchParams();

  const defaultStorageNameValue = searchParams.get('storageUnitName') || '';

  const [variables, setVariables] = useState<PointTrackingQueryVariables>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    storageUnitName: defaultStorageNameValue,
    pointId: searchParams.get('pointId'),
    from: searchParams.get('from'),
    to: searchParams.get('to'),
  });

  const [storageUnitName, setStorageUnitName] = useState<string>(
    defaultStorageNameValue
  );

  const isQueryEnabled = !!variables.pointId;

  const { data, isLoading, isRefetching, error, refetch } =
    usePointTrackingQuery(
      {
        ...variables,
        from: formatDateTimeToIso(variables.from),
        to: formatDateTimeToIso(variables.to),
      },
      {
        enabled: isQueryEnabled,
      }
    );

  useEffect(() => {
    const { pageIndex, pageSize, ...rest } = variables;
    const searchParams: Record<string, string> = {};

    Object.entries(rest)
      .filter((entry) => entry[1])
      .forEach(([key, value]) => {
        if (value) {
          searchParams[key] = value;
        }
      });

    setSearchParams(searchParams);
  }, [searchParams, setSearchParams, variables, variables.pointId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateFilter({ storageUnitName });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [storageUnitName]);

  const tableColumns = useMemo(
    () => [
      tableColumnHelper.accessor((row) => row.timeStampUtc, {
        id: 'date',
        cell: (info) => formatDateTime(info.getValue()),
        header: () => <span>{t('Table.Date')}</span>,
        enableSorting: false,
      }),
      tableColumnHelper.accessor((row) => row?.pointEventType, {
        id: 'type',
        cell: (info) => t(info.getValue()),
        header: () => <span>{t('Table.Type')}</span>,
        enableSorting: false,
      }),
      tableColumnHelper.accessor((row) => row, {
        id: 'additional-info',
        cell: (info) => <StorageUnitAdditionalInfo event={info.getValue()} />,
        header: () => <span></span>,
        enableSorting: false,
      }),
    ],
    [t]
  );

  const eventsTable = useReactTable({
    data: data?.pointTracking?.items || [],
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  const selectPointList =
    pointsList?.points
      ?.filter(notEmpty)
      .map((point) => ({
        label: `${point.pointRegistrationNumber || point.pointSequenceNumber}`,
        value: point?.id || '',
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const updateFilter = (values: Partial<PointTrackingQueryVariables>) => {
    setVariables((current) => ({ ...current, ...values }));
  };

  return (
    <PageLayout title={t('Events.PageTitle')}>
      <PageLayoutContent>
        <FilterTypeSelect active={FiltrType.StorageUnitsTracking} />
        {selectPointList.length !== 0 && (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginBottom="5rem"
          >
            <Grid
              grid
              gridTemplateColumns="35rem 40rem auto"
              gridColumnGap="10rem"
              alignItems="center"
            >
              <Select
                userSingleValue={variables.pointId}
                hasAutocomplete
                options={selectPointList}
                label={t('Filter.Point')}
                onSingleValueChange={(pointId) => updateFilter({ pointId })}
              />
              <Input
                value={storageUnitName}
                placeholder={t('StorageUnitTracking.StorageUnitNumber')}
                label={t('Filter.StorageUnitName')}
                onChange={(ev) => setStorageUnitName(ev.target.value)}
              />
              <Flex gap="3rem" alignItems="center">
                <TimeSelect
                  onChange={(from) => from && updateFilter({ from })}
                  value={variables.from || ''}
                  defaultTime="00:00"
                />
                -
                <TimeSelect
                  onChange={(to) => to && updateFilter({ to })}
                  value={variables.to || ''}
                  defaultTime="23:59"
                />
              </Flex>
            </Grid>
            <Flex alignItems="center" gap="1rem">
              <Button
                disabled={!isQueryEnabled}
                title={t('Filter.Refresh')}
                onClick={refetch}
              />
              <Button
                variant="danger"
                title={t('Filter.CancelFilter')}
                onClick={() => {
                  setVariables((current) => ({
                    pageIndex: current.pageIndex,
                    pageSize: current.pageSize,
                  }));
                  setStorageUnitName('');
                }}
              />
            </Flex>
          </Flex>
        )}
        <EventsTable>
          <Table
            table={eventsTable}
            isEmpty={!data?.pointTracking?.items.length}
            isLoading={(isLoading || isRefetching) && isQueryEnabled}
            emptyState={{
              title: t('Table.EmptyState.Title'),
              description: variables.pointId
                ? t('StorageUnitTracking.EmptyStateDescription')
                : t('StorageUnitTracking.FillPointId'),
            }}
            errorInfo={{
              error,
              extendedDescription: {
                title: t('StorageUnitTracking.Error.Description'),
              },
              shortDescription: {
                title: t('Table.Error.Title'),
                description: t('StorageUnitTracking.Error.Description'),
              },
            }}
          />
        </EventsTable>
        <StyledPaginationWrapper>
          <Pagination
            hideForOnlyOnePage
            activePage={variables.pageIndex || 0}
            pagesCount={data?.pointTracking?.totalPages || 0}
            onChange={(pageIndex) => updateFilter({ pageIndex })}
          />
        </StyledPaginationWrapper>
      </PageLayoutContent>
    </PageLayout>
  );
};
