import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetcher } from '../../utils/gql/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: string; output: string };
  UUID: { input: string; output: string };
};

export type AllocationInspected = {
  __typename?: 'AllocationInspected';
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type AllocationTerminated = {
  __typename?: 'AllocationTerminated';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export type CardDetailsDto = {
  __typename?: 'CardDetailsDto';
  cardExpirationDate?: Maybe<Scalars['String']['output']>;
  cardName?: Maybe<Scalars['String']['output']>;
  cardNumberLastDigits?: Maybe<Scalars['String']['output']>;
};

export enum CardEntryMode {
  Contactless = 'CONTACTLESS',
  Ecr = 'ECR',
  Icc = 'ICC',
  MagneticStripe = 'MAGNETIC_STRIPE',
  ManualEntry = 'MANUAL_ENTRY',
  PayPass = 'PAY_PASS',
  PayWave = 'PAY_WAVE',
  Unknown = 'UNKNOWN',
}

export type ClientPhoneNumber = {
  __typename?: 'ClientPhoneNumber';
  clientType: ClientType;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export enum ClientType {
  Depositor = 'DEPOSITOR',
  Recipient = 'RECIPIENT',
  Sender = 'SENDER',
}

export type ContentDeallocated = {
  __typename?: 'ContentDeallocated';
  contentEventType: ContentEventTypeEnum;
  storageUnitId: Scalars['UUID']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
};

export enum ContentEventTypeEnum {
  Deallocated = 'DEALLOCATED',
  Inspected = 'INSPECTED',
  PaymentAbortInfo = 'PAYMENT_ABORT_INFO',
  PaymentProcessed = 'PAYMENT_PROCESSED',
  PaymentRejected = 'PAYMENT_REJECTED',
  PaymentReversalProcessed = 'PAYMENT_REVERSAL_PROCESSED',
  PaymentReversalRejected = 'PAYMENT_REVERSAL_REJECTED',
  PickedUp = 'PICKED_UP',
  StockedIn = 'STOCKED_IN',
  StockedOut = 'STOCKED_OUT',
  Stored = 'STORED',
  SubmissionCodeValidated = 'SUBMISSION_CODE_VALIDATED',
  Terminated = 'TERMINATED',
  UnknownContentFound = 'UNKNOWN_CONTENT_FOUND',
  VerifyAccessCredentialsSuccess = 'VERIFY_ACCESS_CREDENTIALS_SUCCESS',
}

export type ContentPickedUp = {
  __typename?: 'ContentPickedUp';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type ContentStockedIn = {
  __typename?: 'ContentStockedIn';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  courierIdentifier?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type ContentStockedOut = {
  __typename?: 'ContentStockedOut';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  courierIdentifier?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type ContentStored = {
  __typename?: 'ContentStored';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pinCode?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type ContentTracking =
  | AllocationInspected
  | AllocationTerminated
  | ContentDeallocated
  | ContentPickedUp
  | ContentStockedIn
  | ContentStockedOut
  | ContentStored
  | PaymentAbortInfo
  | PaymentProcessed
  | PaymentRejected
  | PaymentReversalProcessed
  | PaymentReversalRejected
  | SubmissionCodeValidated
  | UnknownContentFound
  | VerifyPickUpAccessCredentialsSuccess;

export type CourierAccessCredentialsVerified = {
  __typename?: 'CourierAccessCredentialsVerified';
  personIdentifier?: Maybe<Scalars['String']['output']>;
  pinCode?: Maybe<Scalars['String']['output']>;
  pointEventType: PointEventTypeEnum;
  timeStampUtc: Scalars['DateTime']['output'];
};

export enum Currency {
  Czk = 'CZK',
  Default = 'DEFAULT',
  Eur = 'EUR',
  Gbp = 'GBP',
  Ron = 'RON',
  Rub = 'RUB',
  Usd = 'USD',
}

export enum CvmFlag {
  MobilePinEntered = 'MOBILE_PIN_ENTERED',
  PinOk = 'PIN_OK',
  SignatureRequired = 'SIGNATURE_REQUIRED',
}

export type ExportSmsDto = {
  __typename?: 'ExportSmsDto';
  messagesCount: Scalars['Int']['output'];
  pointId?: Maybe<Scalars['UUID']['output']>;
  pointRegistrationNumber?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['UUID']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  smsMessagesCount: Scalars['Int']['output'];
  tenantSubjectId?: Maybe<Scalars['UUID']['output']>;
  tenantSubjectName?: Maybe<Scalars['String']['output']>;
};

export enum LockStatus {
  Closed = 'CLOSED',
  Failed = 'FAILED',
  Opened = 'OPENED',
}

export type PaginatedPointTrackingMessageDto = {
  __typename?: 'PaginatedPointTrackingMessageDto';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  items: Array<PointTracking>;
  pageIndex: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PaymentAbortInfo = {
  __typename?: 'PaymentAbortInfo';
  contentEventType: ContentEventTypeEnum;
  pointId?: Maybe<Scalars['String']['output']>;
  systemNumber: Scalars['Int']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
};

export type PaymentProcessed = {
  __typename?: 'PaymentProcessed';
  cardEntryMode: CardEntryMode;
  contentEventType: ContentEventTypeEnum;
  pointId?: Maybe<Scalars['String']['output']>;
  receipt: ReceiptDto;
  request: PaymentRequestDto;
  timeStampUtc: Scalars['DateTime']['output'];
  transactionResponseCode: Scalars['Int']['output'];
  transactionResponseCodeName: TransactionResponseCode;
};

export type PaymentRejected = {
  __typename?: 'PaymentRejected';
  contentEventType: ContentEventTypeEnum;
  hostResponseCode?: Maybe<Scalars['String']['output']>;
  paymentId: Scalars['UUID']['output'];
  pointId?: Maybe<Scalars['String']['output']>;
  receipt: ReceiptDto;
  reservationId?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['String']['output']>;
  systemNumber: Scalars['Int']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
  transactionId: Scalars['UUID']['output'];
  transactionResponseCode: Scalars['Int']['output'];
};

export type PaymentRequestDto = {
  __typename?: 'PaymentRequestDto';
  amount?: Maybe<Scalars['String']['output']>;
  currency: Currency;
  paymentId: Scalars['UUID']['output'];
  reservationId?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['String']['output']>;
  systemNumber: Scalars['Int']['output'];
  transactionId: Scalars['UUID']['output'];
};

export type PaymentReversalProcessed = {
  __typename?: 'PaymentReversalProcessed';
  contentEventType: ContentEventTypeEnum;
  pointId?: Maybe<Scalars['String']['output']>;
  receipt: ReceiptDto;
  request: ReversalRequestDto;
  timeStampUtc: Scalars['DateTime']['output'];
  transactionResponseCode: Scalars['Int']['output'];
  transactionResponseCodeName: TransactionResponseCode;
};

export type PaymentReversalRejected = {
  __typename?: 'PaymentReversalRejected';
  contentEventType: ContentEventTypeEnum;
  hostResponseCode?: Maybe<Scalars['String']['output']>;
  paymentId: Scalars['UUID']['output'];
  pointId?: Maybe<Scalars['String']['output']>;
  reservationId?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['String']['output']>;
  systemNumber: Scalars['Int']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
  transactionId: Scalars['UUID']['output'];
  transactionResponseCode: Scalars['Int']['output'];
  transactionResponseCodeName: TransactionResponseCode;
};

export enum PointEventTypeEnum {
  CourierAccessCredentialsVerified = 'COURIER_ACCESS_CREDENTIALS_VERIFIED',
  StorageUnitStatusChanged = 'STORAGE_UNIT_STATUS_CHANGED',
}

export type PointMessageDto = {
  __typename?: 'PointMessageDto';
  deactivationReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  installationId: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  isConfigured: Scalars['Boolean']['output'];
  isManualLayoutManagement: Scalars['Boolean']['output'];
  isOpenForEdit: Scalars['Boolean']['output'];
  pointIdentifier?: Maybe<Scalars['String']['output']>;
  pointPrefix?: Maybe<Scalars['String']['output']>;
  pointRegistrationNumber?: Maybe<Scalars['String']['output']>;
  pointSequenceNumber: Scalars['Int']['output'];
};

export type PointTracking =
  | CourierAccessCredentialsVerified
  | StorageUnitStatusChanged;

export type Query = {
  __typename?: 'Query';
  contentTracking?: Maybe<Array<Maybe<ContentTracking>>>;
  pointTracking?: Maybe<PaginatedPointTrackingMessageDto>;
  points?: Maybe<Array<Maybe<PointMessageDto>>>;
  sentSmsLogs: Array<ExportSmsDto>;
};

export type QueryContentTrackingArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  pageIndex?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  trackingIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPointTrackingArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  pointId?: InputMaybe<Scalars['UUID']['input']>;
  storageUnitName?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QuerySentSmsLogsArgs = {
  pointId?: InputMaybe<Scalars['UUID']['input']>;
  projectId?: InputMaybe<Scalars['UUID']['input']>;
  smsSentAtFrom?: InputMaybe<Scalars['String']['input']>;
  smsSentAtTo?: InputMaybe<Scalars['String']['input']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']['input']>;
};

export type ReceiptDto = {
  __typename?: 'ReceiptDto';
  aId?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['String']['output']>;
  applicationText?: Maybe<Scalars['String']['output']>;
  authorizationCode?: Maybe<Scalars['String']['output']>;
  batchNumber: Scalars['Int']['output'];
  cardDetails: CardDetailsDto;
  cvmFlag: CvmFlag;
  footer: ReceiptFooterDto;
  header: ReceiptHeaderDto;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  merchantId?: Maybe<Scalars['String']['output']>;
  rrN_SequenceNumber?: Maybe<Scalars['String']['output']>;
  terminalId?: Maybe<Scalars['String']['output']>;
  timeOfPayment: Scalars['DateTime']['output'];
  transactionType: TransactionType;
};

export type ReceiptFooterDto = {
  __typename?: 'ReceiptFooterDto';
  footerLine1?: Maybe<Scalars['String']['output']>;
  footerLine2?: Maybe<Scalars['String']['output']>;
  footerLine3?: Maybe<Scalars['String']['output']>;
  footerLine4?: Maybe<Scalars['String']['output']>;
};

export type ReceiptHeaderDto = {
  __typename?: 'ReceiptHeaderDto';
  headerLine1?: Maybe<Scalars['String']['output']>;
  headerLine2?: Maybe<Scalars['String']['output']>;
  headerLine3?: Maybe<Scalars['String']['output']>;
  headerLine4?: Maybe<Scalars['String']['output']>;
  headerLine5?: Maybe<Scalars['String']['output']>;
};

export type ReversalRequestDto = {
  __typename?: 'ReversalRequestDto';
  amount?: Maybe<Scalars['String']['output']>;
  currency: Currency;
  paymentId: Scalars['UUID']['output'];
  reservationId?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['String']['output']>;
  systemNumber: Scalars['Int']['output'];
  transactionId: Scalars['UUID']['output'];
};

export type StorageUnitStatusChanged = {
  __typename?: 'StorageUnitStatusChanged';
  installationId: Scalars['UUID']['output'];
  lockStatus: LockStatus;
  pointEventType: PointEventTypeEnum;
  pointId: Scalars['UUID']['output'];
  pointRegistrationNumber: Scalars['String']['output'];
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  storageUnitName: Scalars['String']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
};

export type SubmissionCodeValidated = {
  __typename?: 'SubmissionCodeValidated';
  contentEventType: ContentEventTypeEnum;
  isScanned: Scalars['Boolean']['output'];
  isSuccessful: Scalars['Boolean']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
};

export enum TransactionResponseCode {
  AlreadyVoided = 'ALREADY_VOIDED',
  AmountTooLowForCashback = 'AMOUNT_TOO_LOW_FOR_CASHBACK',
  Approved = 'APPROVED',
  ApprovedAlmostFullMemory = 'APPROVED_ALMOST_FULL_MEMORY',
  ApprovedFinancialTransactionAndEetTransactionDeclined = 'APPROVED_FINANCIAL_TRANSACTION_AND_EET_TRANSACTION_DECLINED',
  BadInvoiceNumber = 'BAD_INVOICE_NUMBER',
  BatchIsPendingOrMemoryIsFull = 'BATCH_IS_PENDING_OR_MEMORY_IS_FULL',
  CardNotInserted = 'CARD_NOT_INSERTED',
  CardRemoved = 'CARD_REMOVED',
  CashbackAmountTooLow = 'CASHBACK_AMOUNT_TOO_LOW',
  CashbackNotAllowed = 'CASHBACK_NOT_ALLOWED',
  ChangeOfAmountIsNotAllowed = 'CHANGE_OF_AMOUNT_IS_NOT_ALLOWED',
  DeviceIsBusy = 'DEVICE_IS_BUSY',
  DeviceRequestError = 'DEVICE_REQUEST_ERROR',
  DifferentTotalAmountFromSumOfItemsProducts = 'DIFFERENT_TOTAL_AMOUNT_FROM_SUM_OF_ITEMS_PRODUCTS',
  ErrorReadingCard = 'ERROR_READING_CARD',
  ExpiredCard = 'EXPIRED_CARD',
  IncorrectAcquirer = 'INCORRECT_ACQUIRER',
  InitNotAllowedSettleFirst = 'INIT_NOT_ALLOWED_SETTLE_FIRST',
  InvalidAmount = 'INVALID_AMOUNT',
  InvalidBarcodeLength = 'INVALID_BARCODE_LENGTH',
  InvalidCard = 'INVALID_CARD',
  InvalidCountry = 'INVALID_COUNTRY',
  InvalidManualEntry = 'INVALID_MANUAL_ENTRY',
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
  InvalidPin = 'INVALID_PIN',
  ManualEntryNotAllowed = 'MANUAL_ENTRY_NOT_ALLOWED',
  MaximumCashbackAmountExceeded = 'MAXIMUM_CASHBACK_AMOUNT_EXCEEDED',
  NoBatchTotals = 'NO_BATCH_TOTALS',
  NoTransactionToAbort = 'NO_TRANSACTION_TO_ABORT',
  PaperInTheTerminalPrinterRanOut = 'PAPER_IN_THE_TERMINAL_PRINTER_RAN_OUT',
  PartiallySucceeded = 'PARTIALLY_SUCCEEDED',
  PidDoesntMatch = 'PID_DOESNT_MATCH',
  PinLimitExceeded = 'PIN_LIMIT_EXCEEDED',
  PreValidCard = 'PRE_VALID_CARD',
  ProductsNotAllowed = 'PRODUCTS_NOT_ALLOWED',
  SystemNumberDuplicity = 'SYSTEM_NUMBER_DUPLICITY',
  SystemNumberMissing = 'SYSTEM_NUMBER_MISSING',
  TransactionAborted = 'TRANSACTION_ABORTED',
  TransactionFailed = 'TRANSACTION_FAILED',
  UncompletedPreAuthorizationsInBatch = 'UNCOMPLETED_PRE_AUTHORIZATIONS_IN_BATCH',
  UnknownPhoneOperator = 'UNKNOWN_PHONE_OPERATOR',
  UnknownTransaction = 'UNKNOWN_TRANSACTION',
  UnsupportedCard = 'UNSUPPORTED_CARD',
  UnsupportedSamModule = 'UNSUPPORTED_SAM_MODULE',
  UserCancelled = 'USER_CANCELLED',
  VoidNotAllowed = 'VOID_NOT_ALLOWED',
  WrongCurrency = 'WRONG_CURRENCY',
}

export enum TransactionType {
  Sale = 'SALE',
}

export type UnknownContentFound = {
  __typename?: 'UnknownContentFound';
  actualStorageUnitId: Scalars['UUID']['output'];
  contentEventType: ContentEventTypeEnum;
  expectedStorageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type VerifyPickUpAccessCredentialsSuccess = {
  __typename?: 'VerifyPickUpAccessCredentialsSuccess';
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pinCode?: Maybe<Scalars['String']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type AllocationInspectedFragment = {
  __typename?: 'AllocationInspected';
  firstName?: string | null;
  lastname?: string | null;
  contentEventType: ContentEventTypeEnum;
  timeStampUtc: string;
  email?: string | null;
};

export type AllocationTerminatedFragment = {
  __typename?: 'AllocationTerminated';
  firstName?: string | null;
  lastname?: string | null;
  contentEventType: ContentEventTypeEnum;
  timeStampUtc: string;
  email?: string | null;
};

export type CardDetailsFragment = {
  __typename?: 'CardDetailsDto';
  cardExpirationDate?: string | null;
  cardName?: string | null;
  cardNumberLastDigits?: string | null;
};

export type ContentDeallocatedFragment = {
  __typename?: 'ContentDeallocated';
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type ContentPickedUpFragment = {
  __typename?: 'ContentPickedUp';
  timeStampUtc: string;
  email?: string | null;
  firstName?: string | null;
  lastname?: string | null;
  contentEventType: ContentEventTypeEnum;
};

export type ContentStockedInFragment = {
  __typename?: 'ContentStockedIn';
  courierIdentifier?: string | null;
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type ContentStockedOutFragment = {
  __typename?: 'ContentStockedOut';
  courierIdentifier?: string | null;
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
  email?: string | null;
  firstName?: string | null;
  lastname?: string | null;
};

export type ContentStoredFragment = {
  __typename?: 'ContentStored';
  pinCode?: string | null;
  phoneNumber?: string | null;
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type CourierAccessCredentialsVerifiedFragment = {
  __typename?: 'CourierAccessCredentialsVerified';
  personIdentifier?: string | null;
  pinCode?: string | null;
  pointEventType: PointEventTypeEnum;
  timeStampUtc: string;
};

export type ExportSmsFragment = {
  __typename?: 'ExportSmsDto';
  pointRegistrationNumber?: string | null;
  tenantSubjectName?: string | null;
  projectName?: string | null;
  smsMessagesCount: number;
  messagesCount: number;
};

export type PaymentAbortInfoFragment = {
  __typename?: 'PaymentAbortInfo';
  pointId?: string | null;
  systemNumber: number;
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type PaymentProcessedFragment = {
  __typename?: 'PaymentProcessed';
  cardEntryMode: CardEntryMode;
  pointId?: string | null;
  timeStampUtc: string;
  transactionResponseCode: number;
  transactionResponseCodeName: TransactionResponseCode;
  contentEventType: ContentEventTypeEnum;
  receipt: {
    __typename?: 'ReceiptDto';
    aId?: string | null;
    amount?: string | null;
    applicationText?: string | null;
    authorizationCode?: string | null;
    batchNumber: number;
    cvmFlag: CvmFlag;
    invoiceNumber?: string | null;
    merchantId?: string | null;
    rrN_SequenceNumber?: string | null;
    terminalId?: string | null;
    timeOfPayment: string;
    transactionType: TransactionType;
    footer: {
      __typename?: 'ReceiptFooterDto';
      footerLine1?: string | null;
      footerLine2?: string | null;
      footerLine3?: string | null;
      footerLine4?: string | null;
    };
    header: {
      __typename?: 'ReceiptHeaderDto';
      headerLine1?: string | null;
      headerLine2?: string | null;
      headerLine3?: string | null;
      headerLine4?: string | null;
      headerLine5?: string | null;
    };
    cardDetails: {
      __typename?: 'CardDetailsDto';
      cardExpirationDate?: string | null;
      cardName?: string | null;
      cardNumberLastDigits?: string | null;
    };
  };
  request: {
    __typename?: 'PaymentRequestDto';
    amount?: string | null;
    currency: Currency;
    paymentId: string;
    reservationId?: string | null;
    subjectId?: string | null;
    systemNumber: number;
    transactionId: string;
  };
};

export type PaymentRejectedFragment = {
  __typename?: 'PaymentRejected';
  hostResponseCode?: string | null;
  paymentId: string;
  pointId?: string | null;
  reservationId?: string | null;
  subjectId?: string | null;
  systemNumber: number;
  timeStampUtc: string;
  transactionId: string;
  transactionResponseCode: number;
  contentEventType: ContentEventTypeEnum;
  receipt: {
    __typename?: 'ReceiptDto';
    aId?: string | null;
    amount?: string | null;
    applicationText?: string | null;
    authorizationCode?: string | null;
    batchNumber: number;
    cvmFlag: CvmFlag;
    invoiceNumber?: string | null;
    merchantId?: string | null;
    rrN_SequenceNumber?: string | null;
    terminalId?: string | null;
    timeOfPayment: string;
    transactionType: TransactionType;
    footer: {
      __typename?: 'ReceiptFooterDto';
      footerLine1?: string | null;
      footerLine2?: string | null;
      footerLine3?: string | null;
      footerLine4?: string | null;
    };
    header: {
      __typename?: 'ReceiptHeaderDto';
      headerLine1?: string | null;
      headerLine2?: string | null;
      headerLine3?: string | null;
      headerLine4?: string | null;
      headerLine5?: string | null;
    };
    cardDetails: {
      __typename?: 'CardDetailsDto';
      cardExpirationDate?: string | null;
      cardName?: string | null;
      cardNumberLastDigits?: string | null;
    };
  };
};

export type PaymentRequestFragment = {
  __typename?: 'PaymentRequestDto';
  amount?: string | null;
  currency: Currency;
  paymentId: string;
  reservationId?: string | null;
  subjectId?: string | null;
  systemNumber: number;
  transactionId: string;
};

export type PaymentReversalProcessedFragment = {
  __typename?: 'PaymentReversalProcessed';
  pointId?: string | null;
  timeStampUtc: string;
  transactionResponseCode: number;
  transactionResponseCodeName: TransactionResponseCode;
  contentEventType: ContentEventTypeEnum;
  receipt: {
    __typename?: 'ReceiptDto';
    aId?: string | null;
    amount?: string | null;
    applicationText?: string | null;
    authorizationCode?: string | null;
    batchNumber: number;
    cvmFlag: CvmFlag;
    invoiceNumber?: string | null;
    merchantId?: string | null;
    rrN_SequenceNumber?: string | null;
    terminalId?: string | null;
    timeOfPayment: string;
    transactionType: TransactionType;
    footer: {
      __typename?: 'ReceiptFooterDto';
      footerLine1?: string | null;
      footerLine2?: string | null;
      footerLine3?: string | null;
      footerLine4?: string | null;
    };
    header: {
      __typename?: 'ReceiptHeaderDto';
      headerLine1?: string | null;
      headerLine2?: string | null;
      headerLine3?: string | null;
      headerLine4?: string | null;
      headerLine5?: string | null;
    };
    cardDetails: {
      __typename?: 'CardDetailsDto';
      cardExpirationDate?: string | null;
      cardName?: string | null;
      cardNumberLastDigits?: string | null;
    };
  };
  request: {
    __typename?: 'ReversalRequestDto';
    amount?: string | null;
    currency: Currency;
    paymentId: string;
    reservationId?: string | null;
    subjectId?: string | null;
    systemNumber: number;
    transactionId: string;
  };
};

export type PaymentReversalRejectedFragment = {
  __typename?: 'PaymentReversalRejected';
  hostResponseCode?: string | null;
  paymentId: string;
  pointId?: string | null;
  reservationId?: string | null;
  subjectId?: string | null;
  systemNumber: number;
  timeStampUtc: string;
  transactionId: string;
  transactionResponseCode: number;
  transactionResponseCodeName: TransactionResponseCode;
  contentEventType: ContentEventTypeEnum;
};

export type ReceiptFooterFragment = {
  __typename?: 'ReceiptFooterDto';
  footerLine1?: string | null;
  footerLine2?: string | null;
  footerLine3?: string | null;
  footerLine4?: string | null;
};

export type ReceiptHeaderFragment = {
  __typename?: 'ReceiptHeaderDto';
  headerLine1?: string | null;
  headerLine2?: string | null;
  headerLine3?: string | null;
  headerLine4?: string | null;
  headerLine5?: string | null;
};

export type ReceiptFragment = {
  __typename?: 'ReceiptDto';
  aId?: string | null;
  amount?: string | null;
  applicationText?: string | null;
  authorizationCode?: string | null;
  batchNumber: number;
  cvmFlag: CvmFlag;
  invoiceNumber?: string | null;
  merchantId?: string | null;
  rrN_SequenceNumber?: string | null;
  terminalId?: string | null;
  timeOfPayment: string;
  transactionType: TransactionType;
  footer: {
    __typename?: 'ReceiptFooterDto';
    footerLine1?: string | null;
    footerLine2?: string | null;
    footerLine3?: string | null;
    footerLine4?: string | null;
  };
  header: {
    __typename?: 'ReceiptHeaderDto';
    headerLine1?: string | null;
    headerLine2?: string | null;
    headerLine3?: string | null;
    headerLine4?: string | null;
    headerLine5?: string | null;
  };
  cardDetails: {
    __typename?: 'CardDetailsDto';
    cardExpirationDate?: string | null;
    cardName?: string | null;
    cardNumberLastDigits?: string | null;
  };
};

export type ReversalRequestFragment = {
  __typename?: 'ReversalRequestDto';
  amount?: string | null;
  currency: Currency;
  paymentId: string;
  reservationId?: string | null;
  subjectId?: string | null;
  systemNumber: number;
  transactionId: string;
};

export type StorageUnitStatusChangedFragment = {
  __typename?: 'StorageUnitStatusChanged';
  storageUnitName: string;
  pointRegistrationNumber: string;
  pointId: string;
  installationId: string;
  lockStatus: LockStatus;
  pointEventType: PointEventTypeEnum;
  timeStampUtc: string;
};

export type SubmissionCodeValidatedFragment = {
  __typename?: 'SubmissionCodeValidated';
  isSuccessful: boolean;
  isScanned: boolean;
  contentEventType: ContentEventTypeEnum;
  timeStampUtc: string;
};

export type UnknownContentFoundFragment = {
  __typename?: 'UnknownContentFound';
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type VerifyPickUpAccessCredentialsSuccessFragment = {
  __typename?: 'VerifyPickUpAccessCredentialsSuccess';
  pinCode?: string | null;
  phoneNumber?: string | null;
  timeStampUtc: string;
  email?: string | null;
  firstName?: string | null;
  lastname?: string | null;
  contentEventType: ContentEventTypeEnum;
};

export type ContentTrackingQueryVariables = Exact<{
  trackingIdentifier?: InputMaybe<Scalars['String']['input']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  pageIndex?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type ContentTrackingQuery = {
  __typename?: 'Query';
  contentTracking?: Array<
    | {
        __typename: 'AllocationInspected';
        firstName?: string | null;
        lastname?: string | null;
        contentEventType: ContentEventTypeEnum;
        timeStampUtc: string;
        email?: string | null;
      }
    | {
        __typename: 'AllocationTerminated';
        firstName?: string | null;
        lastname?: string | null;
        contentEventType: ContentEventTypeEnum;
        timeStampUtc: string;
        email?: string | null;
      }
    | {
        __typename: 'ContentDeallocated';
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'ContentPickedUp';
        timeStampUtc: string;
        email?: string | null;
        firstName?: string | null;
        lastname?: string | null;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'ContentStockedIn';
        courierIdentifier?: string | null;
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'ContentStockedOut';
        courierIdentifier?: string | null;
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
        email?: string | null;
        firstName?: string | null;
        lastname?: string | null;
      }
    | {
        __typename: 'ContentStored';
        pinCode?: string | null;
        phoneNumber?: string | null;
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'PaymentAbortInfo';
        pointId?: string | null;
        systemNumber: number;
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'PaymentProcessed';
        cardEntryMode: CardEntryMode;
        pointId?: string | null;
        timeStampUtc: string;
        transactionResponseCode: number;
        transactionResponseCodeName: TransactionResponseCode;
        contentEventType: ContentEventTypeEnum;
        receipt: {
          __typename?: 'ReceiptDto';
          aId?: string | null;
          amount?: string | null;
          applicationText?: string | null;
          authorizationCode?: string | null;
          batchNumber: number;
          cvmFlag: CvmFlag;
          invoiceNumber?: string | null;
          merchantId?: string | null;
          rrN_SequenceNumber?: string | null;
          terminalId?: string | null;
          timeOfPayment: string;
          transactionType: TransactionType;
          footer: {
            __typename?: 'ReceiptFooterDto';
            footerLine1?: string | null;
            footerLine2?: string | null;
            footerLine3?: string | null;
            footerLine4?: string | null;
          };
          header: {
            __typename?: 'ReceiptHeaderDto';
            headerLine1?: string | null;
            headerLine2?: string | null;
            headerLine3?: string | null;
            headerLine4?: string | null;
            headerLine5?: string | null;
          };
          cardDetails: {
            __typename?: 'CardDetailsDto';
            cardExpirationDate?: string | null;
            cardName?: string | null;
            cardNumberLastDigits?: string | null;
          };
        };
        request: {
          __typename?: 'PaymentRequestDto';
          amount?: string | null;
          currency: Currency;
          paymentId: string;
          reservationId?: string | null;
          subjectId?: string | null;
          systemNumber: number;
          transactionId: string;
        };
      }
    | {
        __typename: 'PaymentRejected';
        hostResponseCode?: string | null;
        paymentId: string;
        pointId?: string | null;
        reservationId?: string | null;
        subjectId?: string | null;
        systemNumber: number;
        timeStampUtc: string;
        transactionId: string;
        transactionResponseCode: number;
        contentEventType: ContentEventTypeEnum;
        receipt: {
          __typename?: 'ReceiptDto';
          aId?: string | null;
          amount?: string | null;
          applicationText?: string | null;
          authorizationCode?: string | null;
          batchNumber: number;
          cvmFlag: CvmFlag;
          invoiceNumber?: string | null;
          merchantId?: string | null;
          rrN_SequenceNumber?: string | null;
          terminalId?: string | null;
          timeOfPayment: string;
          transactionType: TransactionType;
          footer: {
            __typename?: 'ReceiptFooterDto';
            footerLine1?: string | null;
            footerLine2?: string | null;
            footerLine3?: string | null;
            footerLine4?: string | null;
          };
          header: {
            __typename?: 'ReceiptHeaderDto';
            headerLine1?: string | null;
            headerLine2?: string | null;
            headerLine3?: string | null;
            headerLine4?: string | null;
            headerLine5?: string | null;
          };
          cardDetails: {
            __typename?: 'CardDetailsDto';
            cardExpirationDate?: string | null;
            cardName?: string | null;
            cardNumberLastDigits?: string | null;
          };
        };
      }
    | {
        __typename: 'PaymentReversalProcessed';
        pointId?: string | null;
        timeStampUtc: string;
        transactionResponseCode: number;
        transactionResponseCodeName: TransactionResponseCode;
        contentEventType: ContentEventTypeEnum;
        receipt: {
          __typename?: 'ReceiptDto';
          aId?: string | null;
          amount?: string | null;
          applicationText?: string | null;
          authorizationCode?: string | null;
          batchNumber: number;
          cvmFlag: CvmFlag;
          invoiceNumber?: string | null;
          merchantId?: string | null;
          rrN_SequenceNumber?: string | null;
          terminalId?: string | null;
          timeOfPayment: string;
          transactionType: TransactionType;
          footer: {
            __typename?: 'ReceiptFooterDto';
            footerLine1?: string | null;
            footerLine2?: string | null;
            footerLine3?: string | null;
            footerLine4?: string | null;
          };
          header: {
            __typename?: 'ReceiptHeaderDto';
            headerLine1?: string | null;
            headerLine2?: string | null;
            headerLine3?: string | null;
            headerLine4?: string | null;
            headerLine5?: string | null;
          };
          cardDetails: {
            __typename?: 'CardDetailsDto';
            cardExpirationDate?: string | null;
            cardName?: string | null;
            cardNumberLastDigits?: string | null;
          };
        };
        request: {
          __typename?: 'ReversalRequestDto';
          amount?: string | null;
          currency: Currency;
          paymentId: string;
          reservationId?: string | null;
          subjectId?: string | null;
          systemNumber: number;
          transactionId: string;
        };
      }
    | {
        __typename: 'PaymentReversalRejected';
        hostResponseCode?: string | null;
        paymentId: string;
        pointId?: string | null;
        reservationId?: string | null;
        subjectId?: string | null;
        systemNumber: number;
        timeStampUtc: string;
        transactionId: string;
        transactionResponseCode: number;
        transactionResponseCodeName: TransactionResponseCode;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'SubmissionCodeValidated';
        isSuccessful: boolean;
        isScanned: boolean;
        contentEventType: ContentEventTypeEnum;
        timeStampUtc: string;
      }
    | {
        __typename: 'UnknownContentFound';
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'VerifyPickUpAccessCredentialsSuccess';
        pinCode?: string | null;
        phoneNumber?: string | null;
        timeStampUtc: string;
        email?: string | null;
        firstName?: string | null;
        lastname?: string | null;
        contentEventType: ContentEventTypeEnum;
      }
    | null
  > | null;
};

export type PointTrackingQueryVariables = Exact<{
  pointId?: InputMaybe<Scalars['UUID']['input']>;
  storageUnitName?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
}>;

export type PointTrackingQuery = {
  __typename?: 'Query';
  pointTracking?: {
    __typename?: 'PaginatedPointTrackingMessageDto';
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    totalCount: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    items: Array<
      | {
          __typename: 'CourierAccessCredentialsVerified';
          personIdentifier?: string | null;
          pinCode?: string | null;
          pointEventType: PointEventTypeEnum;
          timeStampUtc: string;
        }
      | {
          __typename: 'StorageUnitStatusChanged';
          storageUnitName: string;
          pointRegistrationNumber: string;
          pointId: string;
          installationId: string;
          lockStatus: LockStatus;
          pointEventType: PointEventTypeEnum;
          timeStampUtc: string;
        }
    >;
  } | null;
};

export type PointsQueryVariables = Exact<{ [key: string]: never }>;

export type PointsQuery = {
  __typename?: 'Query';
  points?: Array<{
    __typename?: 'PointMessageDto';
    id: string;
    pointSequenceNumber: number;
    pointRegistrationNumber?: string | null;
  } | null> | null;
};

export type SentSmsLogsQueryVariables = Exact<{
  smsSentAtFrom?: InputMaybe<Scalars['String']['input']>;
  smsSentAtTo?: InputMaybe<Scalars['String']['input']>;
}>;

export type SentSmsLogsQuery = {
  __typename?: 'Query';
  sentSmsLogs: Array<{
    __typename?: 'ExportSmsDto';
    pointRegistrationNumber?: string | null;
    tenantSubjectName?: string | null;
    projectName?: string | null;
    smsMessagesCount: number;
    messagesCount: number;
  }>;
};

export const AllocationInspectedFragmentDoc = `
    fragment AllocationInspected on AllocationInspected {
  firstName
  lastname
  contentEventType
  timeStampUtc
  email
}
    `;
export const AllocationTerminatedFragmentDoc = `
    fragment AllocationTerminated on AllocationTerminated {
  firstName
  lastname
  contentEventType
  timeStampUtc
  email
}
    `;
export const ContentDeallocatedFragmentDoc = `
    fragment ContentDeallocated on ContentDeallocated {
  timeStampUtc
  contentEventType
}
    `;
export const ContentPickedUpFragmentDoc = `
    fragment ContentPickedUp on ContentPickedUp {
  timeStampUtc
  email
  firstName
  lastname
  contentEventType
}
    `;
export const ContentStockedInFragmentDoc = `
    fragment ContentStockedIn on ContentStockedIn {
  courierIdentifier
  timeStampUtc
  contentEventType
}
    `;
export const ContentStockedOutFragmentDoc = `
    fragment ContentStockedOut on ContentStockedOut {
  courierIdentifier
  timeStampUtc
  contentEventType
  email
  firstName
  lastname
}
    `;
export const ContentStoredFragmentDoc = `
    fragment ContentStored on ContentStored {
  pinCode
  phoneNumber
  timeStampUtc
  contentEventType
}
    `;
export const CourierAccessCredentialsVerifiedFragmentDoc = `
    fragment CourierAccessCredentialsVerified on CourierAccessCredentialsVerified {
  personIdentifier
  pinCode
  pointEventType
  timeStampUtc
}
    `;
export const ExportSmsFragmentDoc = `
    fragment ExportSms on ExportSmsDto {
  pointRegistrationNumber
  tenantSubjectName
  projectName
  smsMessagesCount
  messagesCount
}
    `;
export const PaymentAbortInfoFragmentDoc = `
    fragment PaymentAbortInfo on PaymentAbortInfo {
  pointId
  systemNumber
  timeStampUtc
  contentEventType
}
    `;
export const ReceiptFooterFragmentDoc = `
    fragment ReceiptFooter on ReceiptFooterDto {
  footerLine1
  footerLine2
  footerLine3
  footerLine4
}
    `;
export const ReceiptHeaderFragmentDoc = `
    fragment ReceiptHeader on ReceiptHeaderDto {
  headerLine1
  headerLine2
  headerLine3
  headerLine4
  headerLine5
}
    `;
export const CardDetailsFragmentDoc = `
    fragment CardDetails on CardDetailsDto {
  cardExpirationDate
  cardName
  cardNumberLastDigits
}
    `;
export const ReceiptFragmentDoc = `
    fragment Receipt on ReceiptDto {
  aId
  amount
  applicationText
  authorizationCode
  batchNumber
  cvmFlag
  invoiceNumber
  merchantId
  rrN_SequenceNumber
  terminalId
  timeOfPayment
  transactionType
  footer {
    ...ReceiptFooter
  }
  header {
    ...ReceiptHeader
  }
  cardDetails {
    ...CardDetails
  }
}
    `;
export const PaymentRequestFragmentDoc = `
    fragment PaymentRequest on PaymentRequestDto {
  amount
  currency
  paymentId
  reservationId
  subjectId
  systemNumber
  transactionId
}
    `;
export const PaymentProcessedFragmentDoc = `
    fragment PaymentProcessed on PaymentProcessed {
  cardEntryMode
  pointId
  timeStampUtc
  transactionResponseCode
  transactionResponseCodeName
  receipt {
    ...Receipt
  }
  request {
    ...PaymentRequest
  }
  contentEventType
}
    `;
export const PaymentRejectedFragmentDoc = `
    fragment PaymentRejected on PaymentRejected {
  hostResponseCode
  paymentId
  pointId
  receipt {
    ...Receipt
  }
  reservationId
  subjectId
  systemNumber
  timeStampUtc
  transactionId
  transactionResponseCode
  contentEventType
}
    `;
export const ReversalRequestFragmentDoc = `
    fragment ReversalRequest on ReversalRequestDto {
  amount
  currency
  paymentId
  reservationId
  subjectId
  systemNumber
  transactionId
}
    `;
export const PaymentReversalProcessedFragmentDoc = `
    fragment PaymentReversalProcessed on PaymentReversalProcessed {
  pointId
  receipt {
    ...Receipt
  }
  request {
    ...ReversalRequest
  }
  timeStampUtc
  transactionResponseCode
  transactionResponseCodeName
  contentEventType
}
    `;
export const PaymentReversalRejectedFragmentDoc = `
    fragment PaymentReversalRejected on PaymentReversalRejected {
  hostResponseCode
  paymentId
  pointId
  reservationId
  subjectId
  systemNumber
  timeStampUtc
  transactionId
  transactionResponseCode
  transactionResponseCodeName
  contentEventType
}
    `;
export const StorageUnitStatusChangedFragmentDoc = `
    fragment StorageUnitStatusChanged on StorageUnitStatusChanged {
  storageUnitName
  pointRegistrationNumber
  pointId
  installationId
  lockStatus
  pointEventType
  timeStampUtc
}
    `;
export const SubmissionCodeValidatedFragmentDoc = `
    fragment SubmissionCodeValidated on SubmissionCodeValidated {
  isSuccessful
  isScanned
  contentEventType
  timeStampUtc
}
    `;
export const UnknownContentFoundFragmentDoc = `
    fragment UnknownContentFound on UnknownContentFound {
  timeStampUtc
  contentEventType
}
    `;
export const VerifyPickUpAccessCredentialsSuccessFragmentDoc = `
    fragment VerifyPickUpAccessCredentialsSuccess on VerifyPickUpAccessCredentialsSuccess {
  pinCode
  phoneNumber
  timeStampUtc
  email
  firstName
  lastname
  contentEventType
}
    `;
export const ContentTrackingDocument = `
    query ContentTracking($trackingIdentifier: String, $tenantSubjectId: UUID, $from: DateTime, $to: DateTime, $pageSize: Int, $pageIndex: Int, $phoneNumber: String) {
  contentTracking(
    trackingIdentifier: $trackingIdentifier
    tenantSubjectId: $tenantSubjectId
    from: $from
    to: $to
    pageSize: $pageSize
    pageIndex: $pageIndex
    phoneNumber: $phoneNumber
  ) {
    __typename
    ... on ContentStockedIn {
      ...ContentStockedIn
    }
    ... on ContentStockedOut {
      ...ContentStockedOut
    }
    ... on ContentStored {
      ...ContentStored
    }
    ... on ContentPickedUp {
      ...ContentPickedUp
    }
    ... on ContentDeallocated {
      ...ContentDeallocated
    }
    ... on UnknownContentFound {
      ...UnknownContentFound
    }
    ... on AllocationInspected {
      ...AllocationInspected
    }
    ... on AllocationTerminated {
      ...AllocationTerminated
    }
    ... on SubmissionCodeValidated {
      ...SubmissionCodeValidated
    }
    ... on VerifyPickUpAccessCredentialsSuccess {
      ...VerifyPickUpAccessCredentialsSuccess
    }
    ... on PaymentAbortInfo {
      ...PaymentAbortInfo
    }
    ... on PaymentProcessed {
      ...PaymentProcessed
    }
    ... on PaymentRejected {
      ...PaymentRejected
    }
    ... on PaymentReversalProcessed {
      ...PaymentReversalProcessed
    }
    ... on PaymentReversalRejected {
      ...PaymentReversalRejected
    }
  }
}
    ${ContentStockedInFragmentDoc}
${ContentStockedOutFragmentDoc}
${ContentStoredFragmentDoc}
${ContentPickedUpFragmentDoc}
${ContentDeallocatedFragmentDoc}
${UnknownContentFoundFragmentDoc}
${AllocationInspectedFragmentDoc}
${AllocationTerminatedFragmentDoc}
${SubmissionCodeValidatedFragmentDoc}
${VerifyPickUpAccessCredentialsSuccessFragmentDoc}
${PaymentAbortInfoFragmentDoc}
${PaymentProcessedFragmentDoc}
${ReceiptFragmentDoc}
${ReceiptFooterFragmentDoc}
${ReceiptHeaderFragmentDoc}
${CardDetailsFragmentDoc}
${PaymentRequestFragmentDoc}
${PaymentRejectedFragmentDoc}
${PaymentReversalProcessedFragmentDoc}
${ReversalRequestFragmentDoc}
${PaymentReversalRejectedFragmentDoc}`;

export const useContentTrackingQuery = <
  TData = ContentTrackingQuery,
  TError = unknown,
>(
  variables?: ContentTrackingQueryVariables,
  options?: Omit<
    UseQueryOptions<ContentTrackingQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<ContentTrackingQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<ContentTrackingQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['ContentTracking']
        : ['ContentTracking', variables],
    queryFn: fetcher<ContentTrackingQuery, ContentTrackingQueryVariables>(
      ContentTrackingDocument,
      variables
    ),
    ...options,
  });
};

export const PointTrackingDocument = `
    query PointTracking($pointId: UUID, $storageUnitName: String, $from: DateTime, $to: DateTime, $pageSize: Int!, $pageIndex: Int!) {
  pointTracking(
    pointId: $pointId
    storageUnitName: $storageUnitName
    from: $from
    to: $to
    pageSize: $pageSize
    pageIndex: $pageIndex
  ) {
    items {
      __typename
      ... on CourierAccessCredentialsVerified {
        ...CourierAccessCredentialsVerified
      }
      ... on StorageUnitStatusChanged {
        ...StorageUnitStatusChanged
      }
    }
    pageIndex
    pageSize
    totalPages
    totalCount
    hasPreviousPage
    hasNextPage
  }
}
    ${CourierAccessCredentialsVerifiedFragmentDoc}
${StorageUnitStatusChangedFragmentDoc}`;

export const usePointTrackingQuery = <
  TData = PointTrackingQuery,
  TError = unknown,
>(
  variables: PointTrackingQueryVariables,
  options?: Omit<
    UseQueryOptions<PointTrackingQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<PointTrackingQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<PointTrackingQuery, TError, TData>({
    queryKey: ['PointTracking', variables],
    queryFn: fetcher<PointTrackingQuery, PointTrackingQueryVariables>(
      PointTrackingDocument,
      variables
    ),
    ...options,
  });
};

export const PointsDocument = `
    query Points {
  points {
    id
    pointSequenceNumber
    pointRegistrationNumber
  }
}
    `;

export const usePointsQuery = <TData = PointsQuery, TError = unknown>(
  variables?: PointsQueryVariables,
  options?: Omit<UseQueryOptions<PointsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<PointsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<PointsQuery, TError, TData>({
    queryKey: variables === undefined ? ['Points'] : ['Points', variables],
    queryFn: fetcher<PointsQuery, PointsQueryVariables>(
      PointsDocument,
      variables
    ),
    ...options,
  });
};

export const SentSmsLogsDocument = `
    query SentSmsLogs($smsSentAtFrom: String, $smsSentAtTo: String) {
  sentSmsLogs(smsSentAtFrom: $smsSentAtFrom, smsSentAtTo: $smsSentAtTo) {
    ...ExportSms
  }
}
    ${ExportSmsFragmentDoc}`;

export const useSentSmsLogsQuery = <TData = SentSmsLogsQuery, TError = unknown>(
  variables?: SentSmsLogsQueryVariables,
  options?: Omit<
    UseQueryOptions<SentSmsLogsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SentSmsLogsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SentSmsLogsQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['SentSmsLogs'] : ['SentSmsLogs', variables],
    queryFn: fetcher<SentSmsLogsQuery, SentSmsLogsQueryVariables>(
      SentSmsLogsDocument,
      variables
    ),
    ...options,
  });
};
